import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString, type Amount } from '@orus.eu/amount'

import type { Invoice } from '@orus.eu/backend/src/invoicing/views/invoice-model'
import {
  PARIS,
  formatTimestampDdMmYyyy,
  getCalendarDateFromTimestamp,
  getStartOfCalendarDate,
  getZonedDateTimeFromMillis,
  type CalendarDate,
} from '@orus.eu/calendar-date'
import { type Period } from '@orus.eu/period'
import {
  Button,
  CheckboxContainer,
  ContentContainerBackoffice,
  DatepickerFormField,
  Dialog,
  Divider,
  DropdownFormField,
  Text,
  TextInputLabelWrapper,
  ValidatedTextField,
  ValidationDialogDivider,
  ValidationDialogRowContent,
  borderStroke,
  colorTokens,
  enqueueTemporaryNotificationAlert,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
} from '@orus.eu/pharaoh'

import { isSuccess } from '@orus.eu/result'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useCallback, useState, type ReactElement } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { amountCustomInputProps, amountMapperWithDecimals } from '../../../../lib/amount'
import { assert } from '../../../../lib/errors'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'

export default function PlatformNewInvoicePageV2(): ReactElement {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/new-invoice' })
  assert(subscriptionId, 'This page required a subscriptionId param')
  const [existingInvoices] = trpcReact.invoices.listInvoices.useSuspenseQuery(subscriptionId)

  const now = getZonedDateTimeFromMillis(Date.now(), PARIS).toMillis()

  const [issueTimestamp, setIssueTimestamp] = useState<number | null>(now)
  const [dueTimestamp, setDueTimestamp] = useState<number | null>(null)
  const [periodStartTimestamp, setPeriodStartTimestamp] = useState<number | null>(null)
  const [periodEndTimestamp, setPeriodEndTimestamp] = useState<number | null>(null)
  const [isFirstOfYear, setIsFirstOfYear] = useState<boolean>(false)
  const [isInFirstYear, setIsInFirstYear] = useState<boolean>(false)
  const [totalPremium, setTotalPremium] = useState<Amount | null>(null)

  const [subscriptionVersionIdsWithLabel] =
    trpcReact.contracts.getContractVersionIdsWithLabel.useSuspenseQuery(subscriptionId)

  const subscriptionVersionIds = subscriptionVersionIdsWithLabel.map(([id]) => id)

  const [subscriptionVersionId, setSubscriptionVersionId] = useState<string | null>(
    subscriptionVersionIds[subscriptionVersionIds.length - 1],
  )

  const handleIssueCalendarDateChange = (value: CalendarDate | null) => {
    const newIssueTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setIssueTimestamp(newIssueTimestamp)
  }

  const handleDueCalendarDateChange = (value: CalendarDate | null) => {
    const newDueTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setDueTimestamp(newDueTimestamp)
  }

  const handleFirstPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodStartTimestamp = value ? getStartOfCalendarDate(value, PARIS).toMillis() : null
    setPeriodStartTimestamp(newPeriodStartTimestamp)
  }

  const handleLastPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodEndTimestamp = value ? getStartOfCalendarDate(value, PARIS).plus({ day: 1 }).toMillis() : null
    setPeriodEndTimestamp(newPeriodEndTimestamp)
  }

  const handleContractVersionChange = (value: string | null) => {
    setSubscriptionVersionId(value)
  }

  const handleTotalPremiumChange = useCallback((value: Amount | null | undefined) => {
    setTotalPremium(value ?? null)
  }, [])

  const issueCalendarDate = issueTimestamp ? getCalendarDateFromTimestamp(issueTimestamp, PARIS) : null
  const dueCalendarDate = dueTimestamp ? getCalendarDateFromTimestamp(dueTimestamp, PARIS) : null
  const firstPeriodDayCalendarDate = periodStartTimestamp
    ? getCalendarDateFromTimestamp(periodStartTimestamp, PARIS)
    : null

  const lastPeriodDayCalendarDate = periodEndTimestamp
    ? getCalendarDateFromTimestamp(
        getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).minus({ day: 1 }).toMillis(),
        PARIS,
      )
    : null

  const adjustedInvoice =
    periodStartTimestamp == undefined || periodEndTimestamp == undefined
      ? undefined
      : existingInvoices.find(
          ({ invoice }) =>
            !isAdjustementInvoice(invoice) &&
            invoicePeriodMatches(invoice, { periodStartTimestamp, periodEndTimestamp }),
        )?.invoice

  const {
    show: showInvoiceConfirmationDialog,
    hide: hideInvoiceConfirmationDialog,
    visible: isInvoiceConfirmationDialogVisible,
  } = useDialogVisibility('invoice-confirmation')

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Nouvelle facture V2</BackofficeSectionTitle>

      <div
        css={css`
          width: 800px;
        `}
      >
        <StyledCard>
          <div>
            <Text variant="h5">Données de facturation</Text>
            <Text variant="body2">Les inputs nécessaires pour créer une facture.</Text>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: flex-start;
              gap: ${spacing[60]};
            `}
          >
            <DatepickerFormField
              label="Premier jour"
              onChange={handleFirstPeriodDayCalendarDateChange}
              value={firstPeriodDayCalendarDate}
              size="small"
            />

            <DatepickerFormField
              label="Dernier jour (inclus)"
              value={lastPeriodDayCalendarDate}
              onChange={handleLastPeriodDayCalendarDateChange}
              size="small"
              minDate={firstPeriodDayCalendarDate ?? undefined}
            />
          </div>
          {adjustedInvoice ? (
            <Text>Cette période étant déjà facturée, une facture d’ajustement sera créée.</Text>
          ) : (
            <></>
          )}

          <DropdownFormField
            css={css`
              width: 320px;
            `}
            value={subscriptionVersionId}
            values={subscriptionVersionIds}
            onChange={handleContractVersionChange}
            size="small"
            placeholder="Version du contrat"
            labels={getContractVersionsLabel(subscriptionVersionIdsWithLabel)}
            label="Version du contrat"
          />

          <div
            css={css`
              display: flex;
              gap: ${spacing[60]};
            `}
          >
            <DatepickerFormField
              label="Date limite de paiement"
              value={dueCalendarDate}
              onChange={handleDueCalendarDateChange}
              size="small"
            />

            <DatepickerFormField
              label="Date d'émission"
              value={issueCalendarDate}
              onChange={handleIssueCalendarDateChange}
              size="small"
            />
          </div>

          <div>
            <CheckboxContainer checked={isFirstOfYear} onChange={() => setIsFirstOfYear(!isFirstOfYear)} size="small">
              La période facturée est la première période d&apos;une année contractuelle.
            </CheckboxContainer>
            <CheckboxContainer checked={isInFirstYear} onChange={() => setIsInFirstYear(!isInFirstYear)} size="small">
              La période facturée est dans la première année contractuelle.
            </CheckboxContainer>
          </div>

          <div
            css={css`
              display: flex;
            `}
          >
            <TextInputLabelWrapper label="Montant total TTC">
              <div
                css={css`
                  display: flex;
                  gap: ${spacing[60]};
                `}
              >
                <ValidatedTextField
                  value={totalPremium}
                  onChange={handleTotalPremiumChange}
                  mapper={amountMapperWithDecimals}
                  size="small"
                  InputProps={amountCustomInputProps}
                />
                {subscriptionVersionId &&
                periodStartTimestamp &&
                periodEndTimestamp &&
                dueTimestamp &&
                issueTimestamp ? (
                  <ComputeTotalPremiumButton
                    subscriptionVersionId={subscriptionVersionId}
                    periodStartTimestamp={periodStartTimestamp}
                    periodEndTimestamp={periodEndTimestamp}
                    isInFirstYear={isInFirstYear}
                    isFirstOfYear={isFirstOfYear}
                    subscriptionId={subscriptionId}
                    dueTimestamp={dueTimestamp}
                    issueTimestamp={issueTimestamp}
                    setTotalPremium={setTotalPremium}
                  />
                ) : (
                  <></>
                )}
              </div>
            </TextInputLabelWrapper>
          </div>

          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button onClick={showInvoiceConfirmationDialog} avatarPosition="left" disabled={!totalPremium}>
              Créer la facture
            </Button>
          </div>
          {isInvoiceConfirmationDialogVisible &&
          totalPremium &&
          periodStartTimestamp &&
          periodEndTimestamp &&
          subscriptionVersionId &&
          issueTimestamp &&
          dueTimestamp ? (
            <InvoiceConfirmationDialog
              onHide={hideInvoiceConfirmationDialog}
              subscriptionId={subscriptionId}
              subscriptionVersionId={subscriptionVersionId}
              periodStartTimestamp={periodStartTimestamp}
              periodEndTimestamp={periodEndTimestamp}
              issueTimestamp={issueTimestamp}
              dueTimestamp={dueTimestamp}
              totalPremium={totalPremium}
              isInFirstYear={isInFirstYear}
              isFirstOfYear={isFirstOfYear}
            />
          ) : (
            <></>
          )}
        </StyledCard>
      </div>
    </ContentContainerBackoffice>
  )
}

const ComputeTotalPremiumButton = memo<{
  subscriptionVersionId: string
  periodStartTimestamp: number
  periodEndTimestamp: number
  isInFirstYear: boolean
  isFirstOfYear: boolean
  subscriptionId: string
  dueTimestamp: number
  issueTimestamp: number
  setTotalPremium: (value: Amount | null) => void
}>(function ComputeTotalPremiumButton({
  subscriptionVersionId,
  periodStartTimestamp,
  periodEndTimestamp,
  isInFirstYear,
  isFirstOfYear,
  subscriptionId,
  dueTimestamp,
  issueTimestamp,
  setTotalPremium,
}): ReactElement {
  const defaultManualInvoiceAmountQuery = trpcReact.invoices.getDefaultManualInvoiceAmount.useQuery({
    subscriptionId,
    subscriptionVersionId,
    periodStartTimestamp,
    periodEndTimestamp,
    isInFirstYear,
    isFirstOfYear,
    dueTimestamp,
    issueTimestamp,
  })

  const defaultManualInvoiceAmount = defaultManualInvoiceAmountQuery.data

  const handleClick = () => {
    if (defaultManualInvoiceAmount) {
      setTotalPremium(defaultManualInvoiceAmount)
    }
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: flex-end;
      `}
    >
      <Button
        variant="secondary"
        size="small"
        onClick={handleClick}
        disabled={defaultManualInvoiceAmountQuery.isLoading}
        isLoading={defaultManualInvoiceAmountQuery.isLoading}
      >
        Calculer le montant total théorique
      </Button>
    </div>
  )
})

export type InvoiceConfirmationDialogProps = {
  onHide: () => void
  subscriptionId: string
  subscriptionVersionId: string
  periodStartTimestamp: number
  periodEndTimestamp: number
  issueTimestamp: number
  dueTimestamp: number
  totalPremium: Amount
  isInFirstYear: boolean
  isFirstOfYear: boolean
}

export const InvoiceConfirmationDialog = memo<InvoiceConfirmationDialogProps>(function InvoiceConfirmationDialog({
  onHide,
  subscriptionId,
  subscriptionVersionId,
  periodStartTimestamp,
  periodEndTimestamp,
  issueTimestamp,
  dueTimestamp,
  totalPremium,
  isInFirstYear,
  isFirstOfYear,
}): ReactElement {
  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)

  const onCreateInvoiceV2 = useAsyncCallback(async () => {
    setCreating(true)

    const creationResult = await trpc.invoices.createManualInvoice.mutate({
      subscriptionId,
      subscriptionVersionId,
      periodStartTimestamp,
      periodEndTimestamp,
      issueTimestamp,
      dueTimestamp,
      totalPremium,
      isInFirstYear,
      isFirstOfYear,
    })

    setCreating(false)

    if (isSuccess(creationResult)) {
      enqueueTemporaryNotificationAlert('Facture créée avec succès', { variant: 'success' })
      await navigate({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId }, replace: true })
    } else {
      enqueueTemporaryNotificationAlert(
        creationProblemMessage[creationResult.problem.type] ?? 'Erreur lors de la création de la facture',
        { variant: 'danger' },
      )
    }
  }, [
    subscriptionId,
    subscriptionVersionId,
    periodStartTimestamp,
    periodEndTimestamp,
    issueTimestamp,
    dueTimestamp,
    totalPremium,
    isInFirstYear,
    isFirstOfYear,
    navigate,
  ])

  const lastPeriodDayTimestamp = getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).minus({ day: 1 }).toMillis()

  return (
    <Dialog
      onClose={onHide}
      title={'Vérification de la facture'}
      size="small"
      onSecondaryAction={onHide}
      secondaryActionLabel="Annuler"
      primaryActionLabel="Confirmer"
      primaryActionDisabled={creating}
      primaryActionLoading={creating}
      onPrimaryAction={onCreateInvoiceV2}
    >
      <div
        css={css`
          width: 100%;
        `}
      >
        <ValidationDialogRowContent title={"Date d'émission"} text={formatTimestampDdMmYyyy(issueTimestamp)} />
        <Divider
          orientation="horizontal"
          css={css`
            margin: ${spacing[40]} 0 ${spacing[40]} 0;
          `}
        />
        <ValidationDialogRowContent title={'Date de paiement'} text={formatTimestampDdMmYyyy(dueTimestamp)} />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Début de la période'}
          text={formatTimestampDdMmYyyy(periodStartTimestamp)}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Fin de la période'}
          text={formatTimestampDdMmYyyy(lastPeriodDayTimestamp)}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={'Montant TTC'}
          text={amountToString(totalPremium, { addCurrency: true, displayDecimals: true })}
        />
      </div>
    </Dialog>
  )
})

function getContractVersionsLabel(contractVersionIdsWithLabel: [string, string][]): { [k: string]: string } {
  return Object.fromEntries(contractVersionIdsWithLabel)
}

function invoicePeriodMatches(invoice: Invoice, period: Period): boolean {
  const invoicePeriod: Period = 'items' in invoice ? invoice.items[0] : invoice
  return (
    invoicePeriod.periodStartTimestamp === period.periodStartTimestamp &&
    invoicePeriod.periodEndTimestamp === period.periodEndTimestamp
  )
}

function isAdjustementInvoice(invoice: Invoice): boolean {
  return !('items' in invoice) && !!invoice.adjustedInvoiceId
}

const StyledCard = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  padding: ${spacing[70]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  border-radius: ${spacing['40']};
`

const creationProblemMessage = {
  'invoice-concurrently-created': 'Erreur : cette facture vient d’être créée en parallèle.',
} as const
